module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-parsely-analytics@1.1.0_2eot2tetnncztrjh5roek5xxyy/node_modules/gatsby-plugin-parsely-analytics/gatsby-browser.js'),
      options: {"plugins":[],"apikey":"chief.com","enableInDevelopment":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@4.15.0_6ehyollrwc7gmu45llldfz4die/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-image@1.15.0_qla7rq2lyxhcwsjtbyopvks224/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@3.2.0_7d6braqpinqsyjwwulzn65lwrq/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cace641016e3ab7839cbfe7d4deb3447"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-segment-js@3.7.2_gatsby@3.15.0/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"B1DGDwhvuBaVEuh7zUXUfOs7yyNzKzdx","devKey":"W0n8TlURyuLeTFGbXoNLKX8vHA2cp244\n","trackPage":false,"delayLoad":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
